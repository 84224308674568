import React, { Fragment, useState } from "react";
import DataTable from "react-data-table-component";
import Breadcrumb from "../../layout/breadcrumb";
import {
    Row, Col, Card, CardBody, CardHeader, Container, Button, Modal, ModalBody, ModalFooter, Form,
    ModalHeader, Label, Input, FormGroup, FormFeedback
} from "reactstrap";
import { useEffect } from "react";
import adminServices from "../../api/adminService";
import { toast } from "react-toastify";
import { API_BASE_URL } from "../../api/url";
import SweetAlert from 'sweetalert2'
import { classes } from "../../data/layouts";
import { useNavigate } from "react-router";



const Category = () => {


    const [data, setData] = useState("");
    const [VaryingContentone, setVaryingContentone] = useState(false);
    const [selectedItem, setSelectedItem] = useState("");
    const [title, setTitle] = useState("");
    const [error, setError] = useState("");
    const [description, setDescription] = useState("");
    const [selectedIcon, SetSelectedIcon] = useState("");
    const defaultLayoutObj = classes.find(item => Object.values(item).pop(1) === 'compact-wrapper');
    const layout = localStorage.getItem('layout') || Object.keys(defaultLayoutObj).pop();

    const history = useNavigate();


    const displayModel = (row) => {
        if (row) {

            SetSelectedIcon(API_BASE_URL + row.icon)
            setSelectedItem(row);
            setTitle(row.title);
            setDescription(row.description);
            setVaryingContentone(!VaryingContentone);
        } else {
            setVaryingContentone(!VaryingContentone);
            setSelectedItem("")
        }

    }

    useEffect(() => {
        async function getCategory() {
            const res = await adminServices.getCategory();
            if (res.status) {
                setData(res.data);
            }

        }
        getCategory()
    }, [0])



    const removeCategory = (row) => {


        SweetAlert.fire({
            title: 'Are you sure?',
            text: "Once deleted, you will not be able to recover this user!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Ok',
            cancelButtonText: 'cancel',
            reverseButtons: true
        }).then((result) => {
            if (result.value) {
                adminServices.updateCategory({ ...row, status: false }, row._id,)
                SweetAlert.fire(
                    'Deleted!',
                    'Your Category has been deleted.',
                    'success'
                )
            }
            else {
                SweetAlert.fire(
                    'Your file is safe!'
                )
            }
        })
    }

    const onViewNavigate = (row) => {
        history(`${process.env.PUBLIC_URL}/app/equipments/${row._id}`, { state: row });
    }

    const tableColumns = [
        {
            name: 'Title',
            selector: row => row.title,
            sortable: true,

        },
        {
            name: 'Icon',
            selector: row => {
                if (row.icon) {
                    return <img
                        width={50}
                        height={50}
                        src={API_BASE_URL + row.icon}
                    />
                } else {
                    return <img
                        width={50}
                        height={50}
                        src={require("../../assets/images/Icons/logo.png")}
                    />
                }

            },
            sortable: true,

        },
        {
            name: 'Status',
            selector: row => "" + row.status,
            sortable: true,

        },
        {
            name: 'View',
            selector: row => {
                return <Button color="primary"
                    onClick={() => onViewNavigate(row)}
                >view</Button>
            },
            sortable: true,

        },

        {
            name: 'Action',
            selector: row => {
                return <div>
                    <span><i className="fa fa-pencil" style={{ width: 35, fontSize: 16, padding: 11, color: 'rgb(40, 167, 69)' }}
                        onClick={() => displayModel(row)} ></i></span>
                    <span><i className="fa fa-trash" style={{ width: 35, fontSize: 16, padding: 11, color: '#e4566e' }}
                        onClick={() => removeCategory(row)}


                    ></i></span>
                </div>
            },
            sortable: true,
            center: true,
        },
    ]



    const onSave = async () => {



        if (!title) {
            setError("Please enter the Title");
            return;
        }

        const formData = new FormData();
        formData.append("title", title);
        formData.append("icon", selectedIcon);
        formData.append("description", description);


        if (selectedItem) {

            const res = await adminServices.updateCategory(formData, selectedItem._id);
            if (res.status) {
                toast(res.message);
                displayModel();
            } else {
                toast.error(res.message);
            }

        } else {


            const res = await adminServices.addCategory(formData);

            if (res.status) {
                toast(res.message);
                displayModel()
            } else {
                toast.error(res.message)
            }
        }






    }



    let icon = ""
    if (selectedIcon) {
        if (selectedItem) {
            icon = selectedIcon;
        } else {
            icon = URL.createObjectURL(selectedIcon);
        }
    }

    return (
        <Fragment>
            <Breadcrumb parent="Category" title="Category" />
            <Container fluid={true}>
                <Row>
                    <Col sm="12">
                        <Card>
                            <CardHeader style={{ display: "flex", flexDirection: "row", justifyContent: "flex-end" }}>


                                <Button color="primary" onClick={() => displayModel()}>{"Add"}</Button>

                                <Modal isOpen={VaryingContentone} toggle={displayModel}>
                                    <ModalHeader toggle={displayModel}>
                                        {"Category"}
                                    </ModalHeader>
                                    <ModalBody>
                                        <Form>
                                            <FormGroup>
                                                <Label className="col-form-label" for="recipient-name">{"Title:"}</Label>
                                                <Input className="form-control"
                                                    type="text" value={title} defaultValue={""}
                                                    onChange={(e) => {
                                                        setTitle(e.target.value)
                                                        setError("")
                                                    }}
                                                    valid={error}
                                                    invalid={error}
                                                />

                                                <FormFeedback>{error}</FormFeedback>

                                            </FormGroup>
                                            <FormGroup>
                                                <Label className="col-form-label" for="message-text">{"Description:"}</Label>
                                                <Input type="textarea" className="form-control" id="message-text"
                                                    value={description} defaultValue={""}
                                                    onChange={(event) => setDescription(event.target.value)}></Input>
                                            </FormGroup>
                                            <FormGroup>
                                                <Label className="col-form-label" for="recipient-name">{"Icon:"}</Label>


                                                <img
                                                    width={100}
                                                    height={100}
                                                    style={{ marginLeft: 10 }}
                                                    src={icon}

                                                />

                                                <input
                                                    style={{ marginTop: 10 }}
                                                    onChange={(e) => SetSelectedIcon(e.target.files[0])}
                                                    // value={selectedIcon}
                                                    defaultValue={""}
                                                    accept="image/*"
                                                    className="form-control" type="file" />
                                            </FormGroup>

                                        </Form>
                                    </ModalBody>
                                    <ModalFooter>
                                        <Button color="secondary" onClick={displayModel}>{"Close"}</Button>
                                        <Button color="primary" onClick={() => onSave()}>{"Save"}</Button>
                                    </ModalFooter>
                                </Modal>

                            </CardHeader>
                            <CardBody>

                                <DataTable
                                    data={data}
                                    columns={tableColumns}
                                    center={true}

                                />
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </Fragment>
    )
}
export default Category;