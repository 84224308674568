import React, { Fragment, useState } from "react";
import { Row, Col, FormGroup } from "react-bootstrap";
import { useLocation } from "react-router";
import { toast } from "react-toastify";
import { Card, Container, Input, Label, CardBody, Button } from "reactstrap";
import adminServices from "../../api/adminService";

import Breadcrumb from "../../layout/breadcrumb";


const Address = (props) => {

    const location = useLocation();
    const userId = location.state;

    const [formValues, setFormValues] = useState({
        name: "",
        city: "",
        state: "",
        country: "",
        complete_address: "",
        pin_code: "",
        latitude: "",
        longitude: ""
    })

    const handleOnChange = (event) => {
        setFormValues({ ...formValues, [event.target.name]: event.target.value })
    }

    const handleSubmit = async () => {

        let data = { ...formValues, location: { type: "Point", coordinates: [formValues.latitude, formValues.longitude] } };

        const res = await adminServices.addAddress(userId, data);
        if (res.status) {
            toast(res.message)
        } else {
            toast.error(res.message)
        }

    }


    return (
        <Fragment>
            <Breadcrumb title="Add Address" />

            <Container fluid={true}>
                <Card>
                    <CardBody>
                        <Row>
                            <Col style={{ margin: '10px' }} sm="4">

                                <Label>{"Name"}</Label>
                                <Input
                                    className="form-control"
                                    type="text"
                                    name="name"
                                    value={formValues.name}
                                    onChange={(e) => handleOnChange(e)}
                                    required
                                    placeholder={"Name"}

                                />
                            </Col>

                            <Col style={{ margin: '10px' }} sm="4">

                                <Label>{"City"}</Label>
                                <Input
                                    className="form-control"
                                    type="text"
                                    name="city"
                                    value={formValues.city}
                                    onChange={(e) => handleOnChange(e)}
                                    required
                                    placeholder={"City"}

                                />
                            </Col>


                            <Col style={{ margin: '10px' }} sm="4">

                                <Label>{"State"}</Label>
                                <Input
                                    className="form-control"
                                    type="text"
                                    name="state"
                                    value={formValues.state}
                                    onChange={(e) => handleOnChange(e)}
                                    required
                                    placeholder={"State"}

                                />
                            </Col>

                            <Col style={{ margin: '10px' }} sm="4">

                                <Label>{"Country"}</Label>
                                <Input
                                    className="form-control"
                                    type="text"
                                    name="country"
                                    value={formValues.country}
                                    onChange={(e) => handleOnChange(e)}
                                    required
                                    placeholder={"Country"}

                                />
                            </Col>

                            <Col style={{ margin: '10px' }} sm="4">

                                <Label>{"Pin Code"}</Label>
                                <Input
                                    className="form-control"
                                    type="text"
                                    name="pin_code"
                                    value={formValues.pin_code}
                                    onChange={(e) => handleOnChange(e)}
                                    required
                                    placeholder={"Pin Code"}

                                />
                            </Col>

                            <Col style={{ margin: '10px' }} sm="12">

                                <Label>{"Complete Address"}</Label>
                                <Input
                                    className="form-control"
                                    type="text"
                                    name="complete_address"
                                    value={formValues.complete_address}
                                    onChange={(e) => handleOnChange(e)}
                                    required
                                    placeholder={"Complete Address"}

                                />
                            </Col>

                            <Col style={{ margin: '10px' }} sm="4">

                                <Label>{"Latitude"}</Label>
                                <Input
                                    className="form-control"
                                    type="text"
                                    name="latitude"
                                    value={formValues.latitude}
                                    onChange={(e) => handleOnChange(e)}
                                    required
                                    placeholder={"Latitude"}

                                />
                            </Col>

                            <Col style={{ margin: '10px' }} sm="4">

                                <Label>{"Longitude"}</Label>
                                <Input
                                    className="form-control"
                                    type="text"
                                    name="longitude"
                                    value={formValues.longitude}
                                    onChange={(e) => handleOnChange(e)}
                                    required
                                    placeholder={"Longitude"}

                                />
                            </Col>
                        </Row>

                        <Button color="primary" className="me-3 m-t-1" onClick={() => handleSubmit()}>{"Save"}</Button>

                    </CardBody>

                </Card>

            </Container>
        </Fragment>
    )
}

export default Address;