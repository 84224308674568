import React, { Fragment, useState } from "react";
import { useEffect } from "react";
import adminServices from "../../api/adminService";
import { Row, Col, Card, CardBody, CardHeader, Button, Modal, ModalHeader, ModalBody, ModalFooter, Input } from "reactstrap";
import { Container } from "react-bootstrap";
import DataTable from "react-data-table-component";
import SweetAlert from 'sweetalert2'
import { useNavigate } from "react-router";
import moment from "moment/moment";
import { classes } from '../../data/layouts'
import Breadcrumb from "../../layout/breadcrumb";

import Select from 'react-select'

const options = [
    { value: 'all', label: 'All' },
    { value: 'Pending', label: 'Pending' },
    { value: 'Submitted', label: 'Reserved' }
]

const SubmittedBookings = () => {

    const [data, setData] = useState("")

    const [status, setStatus] = useState("Submitted");

    const [selectedRows, setSelectedRows] = useState([]);

    const [showQuotation, setShowQuotation] = useState(false);

    const [quotation, setQuotation] = useState({});


    const history = useNavigate();
    const defaultLayoutObj = classes.find(item => Object.values(item).pop(1) === 'compact-wrapper');
    const layout = localStorage.getItem('layout') || Object.keys(defaultLayoutObj).pop();
    var object = {
        status: "Pending",
    };
    const objString = new URLSearchParams(object).toString();
    useEffect(() => {
        async function getBookings() {
            let qryString = "";
            if (status !== 'all') {
                qryString = new URLSearchParams({ status: status }).toString()
            };
            const res = await adminServices.getBookings(qryString);
            if (res.status) {
                setData(res.data)
            } else {
                setData([]);
            }
        }
        getBookings()
    }, [status])
    const ModalToggle = () => setShowQuotation(!showQuotation);

    const saveQuotation = async () => {


        // let ids = data.map((d) => d._id);

        // const enqids = { ...quotation, enquries: ids, user_id: booking.user_id };
        // const res = await adminServices.saveQuotation(booking._id, enqids);
        // if (res.status) {
        //     ModalToggle();
        // } else {
        //     console.log("XJKK", res);
        // }
    }


    const onChangeText = (event, key) => {
        setQuotation(data => {
            return { ...data, [key]: event.target.value };
        })
    }


    const tableColumns = [
        {
            name: 'Booking ID',
            selector: row => row.uid,
            sortable: true,

        },
        {
            name: 'Operators',
            selector: row => row.equipment_id.title,
            sortable: true,

        },
        {
            name: 'Quantity',
            selector: row => row.quantity,
            sortable: true,
            center: true,
        },
        {
            name: 'Shift',
            selector: row => row.shift,
            sortable: true,
            center: true,
        },
        {
            name: 'Specifications',
            selector: row => row.specifications,
            sortable: true,
            center: true,
        },
        {
            name: 'Status',
            selector: row => row.status,
            sortable: true,
            center: true,
        },
        {
            name: ' From',
            selector: row => {

                return moment(row.rental_period[0]).format("DD-MM-YYYY")

            },
            sortable: true,
            center: true,
        },
        {
            name: ' To',
            selector: row => {

                return moment(row.rental_period[1]).format("DD-MM-YYYY")

            },
            sortable: true,
            center: true,
        },
        {
            name: 'Booked At',
            selector: row => {
                return moment(row.createdAt).diff(moment(), "days")
            },
            sortable: true,
            center: true,
        },
        {
            name: 'View',
            selector: row => {
                return <div>
                    <Button color="primary"
                        onClick={() => onViewNavigate(row)}>View</Button>
                </div>
            },
            sortable: true,
            center: true,
        }
    ]

    const onViewNavigate = (row) => {
        history(`${process.env.PUBLIC_URL}/app/bookingDetails/${row._id} `, { state: row });
    }

    const onViewEnquiries = (row) => {
        history(`${process.env.PUBLIC_URL}/app/bookingEquiries/${row._id}`, { state: row });
    }

    const handleRowSelected = React.useCallback(state => {
        // setSelectedRows(state.selectedRows);
        let rows = state.selectedRows;
        let selectedId = []
        // {
        //     rows.map((d) => {
        //         return selectedId.push(d._id)
        //     })
        // }
        setSelectedRows(rows);
        // console.log("selectedId", selectedId)
    }, []);

    const contextActions = React.useMemo(() => {
        return (
            <Button key="delete"
                onClick={() => { }}
                style={{ backgroundColor: 'green' }} icon>
                Generate Quotation
            </Button>
        );
    }, [selectedRows]);

    console.log("X", selectedRows);


    return (
        <Fragment>
            <Breadcrumb parent="Table" title="Bookings" renderOptions={() => <Select value={status} onChange={event => {
                setStatus(event.value);
            }} styles={{ width: '20%', margin: '2%' }} options={options} />} />

            <Container fluid={true}>
                {
                    selectedRows.length > 0 && <div style={{ display: 'flex', justifyContent: "flex-end", marginBottom: '10px' }}><Button onClick={() => setShowQuotation(!showQuotation)}>Generate Quotation</Button> </div>
                }
                <Row>
                    <Col sm="12">
                        <Card>

                            <CardBody>
                                <DataTable
                                    data={data}
                                    columns={tableColumns}
                                    selectableRows
                                    contextActions={contextActions}
                                    onSelectedRowsChange={handleRowSelected}
                                    center={true}

                                />
                            </CardBody>
                        </Card>
                    </Col>
                </Row>

                <Modal size="lg" isOpen={showQuotation} toggle={ModalToggle}>
                    <ModalHeader toggle={ModalToggle} >
                        {"New Quotation"}
                    </ModalHeader>
                    <ModalBody>

                        <Container fluid={true} className="bd-example-row">
                            {
                                selectedRows.map((d) => {
                                    return <div className="mt-3">
                                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', display: 'flex' }}>
                                            <div>
                                                <p>UID</p>
                                                <p>{d.uid}</p>
                                            </div>
                                            <div>
                                                <p>Qty</p>
                                                <p>{d.quantity}</p>
                                            </div>
                                            <div>
                                                <p>Duration</p>
                                                <p>{d.uid}</p>
                                            </div>
                                        </div>
                                        <Row>
                                            <Col md="4">
                                                <Input defaultValue={d.quantity} onChange={(event) => onChangeText(event, "quantity")} value={quotation.quantity} placeholder="Quantity" />
                                            </Col>

                                            <Col md="4">
                                                <Input onChange={(event) => onChangeText(event, "amount")} value={quotation.amount} placeholder="Amount" />
                                            </Col>
                                            <Col md="4">
                                                <p>Price</p>
                                                {/* <Input onChange={(event) => onChangeText(event, "amount")} value={quotation.amount} placeholder="Amount" /> */}
                                            </Col>
                                        </Row>
                                        <hr />

                                    </div>
                                })
                            }
                            <Row className="mt-3">
                                <Col md="12">
                                    Terms
                                </Col>
                                <Col md="12" className="ms-auto">
                                    <Input onChange={(event) => onChangeText(event, "terms")} value={quotation.terms} type="textarea" placeholder="Terms" />
                                </Col>
                            </Row>

                            <Row className="mt-3">
                                <Col md="12">
                                    Remarks
                                </Col>
                                <Col md="12" className="ms-auto">
                                    <Input onChange={(event) => onChangeText(event, "remarks")} value={quotation.remarks} type="textarea" placeholder="Remarks" />
                                </Col>
                            </Row>
                        </Container>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="secondary" onClick={ModalToggle}>{"Close"}</Button>
                        <Button color="primary" onClick={() => saveQuotation()}>{"SaveChanges"}</Button>
                    </ModalFooter>
                </Modal>

            </Container>
        </Fragment>
    )

}
export default SubmittedBookings;