import React, { Fragment, useState } from "react";
import { useEffect } from "react";
import adminServices from "../../api/adminService";
import { Row, Col, Card, CardBody, CardHeader, Button, Table } from "reactstrap";
import { Container } from "react-bootstrap";
import DataTable from "react-data-table-component";
import SweetAlert from 'sweetalert2'
import { useNavigate } from "react-router";
import Breadcrumb from "../../layout/breadcrumb";
import { classes } from '../../data/layouts'
import moment from "moment/moment";

const Enquiries = () => {

    const [data, setData] = useState([])

    const history = useNavigate();
    const defaultLayoutObj = classes.find(item => Object.values(item).pop(1) === 'compact-wrapper');
    const layout = localStorage.getItem('layout') || Object.keys(defaultLayoutObj).pop();


    useEffect(() => {
        async function getEnquiries() {
            const res = await adminServices.getEnquiries();
            if (res.status) {
                setData(res.data)
            }
            else {
                setData("")
            }
        }
        getEnquiries();
    }, [0])


    const onEnquires = (d) => {
        history(`${process.env.PUBLIC_URL}/app/enquiriesdata/${d._id}`, { state: d });
    }



    const tableColumns = [
        // {
        //     name: 'Name',
        //     selector: row => row.name,
        //     sortable: true,

        // },
        // {
        //     name: 'Key',
        //     selector: row => row.key,
        //     sortable: true,
        //     center: true,
        // },
        // {
        //     name: 'Type',
        //     selector: row => row.type,
        //     sortable: true,
        //     center: true,
        // },
        // {
        //     name: 'Backpage',
        //     selector: row => row.backpage,
        //     sortable: true,
        //     center: true,
        // },
        // {
        //     name: 'Action',
        //     selector: row => {
        //         return <div>
        //             <span><i className="fa fa-pencil" style={{ width: 35, fontSize: 16, padding: 11, color: 'rgb(40, 167, 69)' }} onClick={() => onUpdateDocument(row)}></i></span>
        //             <span><i className="fa fa-trash" style={{ width: 35, fontSize: 16, padding: 11, color: '#e4566e' }} onClick={() => onRemoveDocument(row._id)}></i></span>
        //         </div>
        //     },
        //     sortable: true,
        //     center: true,
        // },
    ]




    return (
        <Fragment>
            <Breadcrumb parent="Table" title="Enquiries" />
            <Container fluid={true}>
                <Row>
                    <Col sm="12">
                        <Card>

                            <CardBody>
                                {
                                    data.length === 0 ? <h6 className='text-center'>{"No data found"}</h6> : <Row>
                                        <Col sm="12">

                                            <Row className="card-block">
                                                <Col sm="12" lg="12" xl="12">
                                                    <div className="table-responsive">
                                                        <Table className="table-styling">
                                                            <thead>
                                                                <tr>
                                                                    <th scope="col">{"Id"}</th>
                                                                    <th scope="col" className="table_data">{"Status"}</th>
                                                                    <th scope="col">{"Rental Period from"}</th>

                                                                    <th scope="col">{"to"}</th>
                                                                    <th scope="col">{"Quantity"}</th>
                                                                    <th scope="col">{"Shift"}</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {
                                                                    data && data?.map((d) => {
                                                                        return <tr onClick={() => onEnquires(d)}>
                                                                            <td>
                                                                                {d.uid}
                                                                            </td>
                                                                            <td>
                                                                                {d.status}
                                                                            </td>
                                                                            <td>
                                                                                {d.booking_id && moment(d.booking_id.rental_period[0]).format("DD-MM-YYYY")}
                                                                            </td>
                                                                            <td>
                                                                                {d.booking_id && moment(d.booking_id.rental_period[1]).format("DD-MM-YYYY")}
                                                                            </td>
                                                                            <td>
                                                                                {d.booking_id.quantity}
                                                                            </td>
                                                                            <td>
                                                                                {d.booking_id.shift}
                                                                            </td>
                                                                        </tr>









                                                                    })
                                                                }

                                                                {/* {data.map((d) => {
                                                                            return <tr onClick={() => onSelect(d)} >
                                                                                <td>{d.name}</td>
                                                                                <td>{d.email}</td>

                                                                                <td>{d.phone}</td>
                                                                                <td>{d.type}</td>
                                                                                <td>
                                                                                    <span><i className="fa fa-pencil" style={{ width: 35, fontSize: 16, padding: 11, color: 'rgb(40, 167, 69)' }} onClick={() => onUserEdit(d)}></i></span>
                                                                                </td>
                                                                            </tr>
                                                                        })} */}
                                                            </tbody>
                                                        </Table>
                                                    </div>
                                                </Col>
                                            </Row>

                                        </Col>


                                    </Row>
                                }


                                {/* {data.length > 0 ? <Row className="card-block">
                                    <Col sm="12" lg="12" xl="12">
                                        <div className="table-responsive">
                                            <Table className="table-styling">
                                                <thead>
                                                    <tr>
                                                        <th scope="col">{"Id"}</th>
                                                        <th scope="col">{"Status"}</th>

                                                        <th scope="col">{"Rental Period From"}</th>
                                                        <th scope="col">{"Rental Period From"}</th>
                                                        <th scope="col">{"Quanitity"}</th>
                                                        <th scope="col">{"Shift"}</th>
                                                        <th scope="col">{"Brand"}</th>
                                                        <th scope="col">{"Model"}</th>
                                                    </tr>
                                                </thead>

                                                <tbody>

                                              
                                                </tbody>
                                            </Table>







                                        </div>
                                    </Col>
                                </Row>

                                    : "No data found"}

                             */}
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </Fragment>
    )

}
export default Enquiries;