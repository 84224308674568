import React, { Fragment, useState } from "react";
import { useEffect } from "react";
import adminServices from "../../api/adminService";
import { Row, Col, Card, CardBody, CardHeader, Button } from "reactstrap";
import { Container } from "react-bootstrap";
import DataTable from "react-data-table-component";
import SweetAlert from 'sweetalert2'
import { useNavigate } from "react-router";
import moment from "moment/moment";
import { classes } from '../../data/layouts'
import Breadcrumb from "../../layout/breadcrumb";

const Bookings = () => {

    const [data, setData] = useState([]);

    const history = useNavigate();
  
    var object = {
        status: "Pending"
    };
    const objString = new URLSearchParams(object).toString();
    
    useEffect(() => {
        async function getBookings() {

            const res = await adminServices.getBookings(objString);
            if (res.status) {
                setData(res.data)
            }
            else {
                setData("")
            }

        }
        getBookings()

    }, [0])




    const tableColumns = [
        {
            name: 'Equipment',
            selector: row => row.equipment_id.title,
            sortable: true,

        },
        {
            name: 'Quantity',
            selector: row => row.quantity,
            sortable: true,
            center: true,
        },
        {
            name: 'Shift',
            selector: row => row.shift,
            sortable: true,
            center: true,
        },
        {
            name: 'Specifications',
            selector: row => row.specifications,
            sortable: true,
            center: true,
        },
        {
            name: 'Job Site',
            selector: row => row.jobsite_id.name,
            sortable: true,
            center: true,
        },
        {
            name: 'Status',
            selector: row => row.status,
            sortable: true,
            center: true,
        },
        {
            name: 'From',
            selector: row => {
                return moment(row.rental_period[0]).format("DD-MM-YYYY")

            },
            sortable: true,
            center: true,
        },
        {
            name: 'To',
            selector: row => {
                return moment(row.rental_period[1]).format("DD-MM-YYYY")

            },
            sortable: true,
            center: true,
        },
        {
            name: 'Booked At',
            selector: row => {
                return moment().diff(moment(row.createdAt), "days") + " days ago"
            },
            sortable: true,
            center: true,
        },
        {
            name: 'View',
            selector: row => {
                return <div>
                    <Button color="primary"
                        onClick={() => onViewNavigate(row)}>View</Button>
                </div>
            },
            sortable: true,
            center: true,
        },

    ]

    const onViewNavigate = (row) => {
        history(`${process.env.PUBLIC_URL}/app/bookingDetails/${row._id}`, { state: row });
    }



    return (
        <Fragment>
            <Breadcrumb parent="Table" title="Pending Bookings" />
            <Container fluid={true}>
                <Row>
                    <Col sm="12">
                        <Card>

                            <CardBody>
                                <DataTable
                                    data={data}
                                    columns={tableColumns}
                                    center={true}

                                />
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </Fragment>
    )

}
export default Bookings;