import React, { useState, Fragment } from "react";
import { useLocation, useNavigate } from "react-router";
import { toast } from "react-toastify";

import { Row, Col, Card, CardBody, CardFooter, Form, FormGroup, Label, Input, FormFeedback, Button } from "reactstrap";
import adminServices from "../../api/adminService";
import Breadcrumb from '../../layout/breadcrumb'
import { data } from "../charts/chartsjs/chartsData";
import { classes } from '../../data/layouts'

const AdminDropdown = () => {

    let location = useLocation();
    const data = location.state;
    const navigate = useNavigate();
    const defaultLayoutObj = classes.find(item => Object.values(item).pop(1) === 'compact-wrapper');
    const layout = localStorage.getItem('layout') || Object.keys(defaultLayoutObj).pop();

    const [formValues, setFormValues] = useState({
        type: data?.type ? data.type : "",
        title: data?.title ? data.title : "",
        value: data?.value ? data.value : ""
    })

    const [formErrors, setFormErrors] = useState({
        title: "", type: ""
    })


    const handleOnChange = (event) => {
        setFormValues({ ...formValues, [event.target.name]: event.target.value });
        setFormErrors({ ...formErrors, [event.target.name]: "" });
    }

    const onSubmit = async () => {


        let errors = {};
        if (!formValues.title) {
            errors.title = "Please enter the title";
        }

        if (!formValues.type) {
            errors.type = "Please select the type";
        }

        if (Object.keys(errors).length > 0) {
            setFormErrors(errors);

            return;
        }

        if (data) {
            const res = await adminServices.updateDropDown(data._id, {
                title: formValues.title,
                value: formValues.value
            }, formValues.type)
            if (res.status) {
                toast(res.message);
                navigate(`${process.env.PUBLIC_URL}/app/dropdowns/${layout}`);
            } else {
                toast.error(res.message)
            }

        } else {

            const res = await adminServices.dropDown({
                title: formValues.title,
                value: formValues.value
            }, formValues.type)
            if (res.status) {
                toast(res.message);
                navigate(`${process.env.PUBLIC_URL}/app/dropdowns/${layout}`);
            } else {
                toast.error(res.message)
            }
        }







    }


    return (
        <Fragment>

            <Breadcrumb title="Dropdown" />

            <Row>
                <Col sm="12">
                    <Card>
                        <CardBody>
                            <Form className="theme-form">
                                <Row>

                                    <Col sm="4">
                                        <FormGroup className='m-t-1'>
                                            <Label>{"Type"}</Label>
                                            <Input className="form-control"
                                                type="text"
                                                name="type"
                                                placeholder={"Type"}
                                                onChange={(e) => handleOnChange(e)}
                                                required="true"
                                                value={formValues.type}
                                                valid={formErrors.type}
                                                invalid={formErrors.type}

                                            />

                                            <FormFeedback>
                                                {
                                                    formErrors.type
                                                }
                                            </FormFeedback>
                                        </FormGroup>
                                    </Col>
                                    <Col sm="4">

                                        <FormGroup className='m-t-1'>
                                            <Label>{"Title"}</Label>
                                            <Input
                                                className="form-control"
                                                type="text"
                                                name="title"
                                                value={formValues.title}
                                                defaultValue=""
                                                valid={formErrors.title}
                                                invalid={formErrors.title}
                                                onChange={(e) => handleOnChange(e)}

                                                required
                                                placeholder={"Title"}


                                            />
                                            <FormFeedback>
                                                {
                                                    formErrors.title
                                                }
                                            </FormFeedback>
                                        </FormGroup>
                                    </Col>

                                    <Col sm="4">
                                        <FormGroup className='m-t-1'>
                                            <Label>{"Value"}</Label>
                                            <Input className="form-control"
                                                type="text"
                                                name="value"
                                                placeholder={"Value"}
                                                onChange={(e) => handleOnChange(e)}

                                                required="true"
                                                value={formValues.value}


                                            />

                                        </FormGroup>
                                    </Col>


                                </Row>



                            </Form>
                        </CardBody>
                        <CardFooter>
                            <Button color="primary" className="me-3 " onClick={() => onSubmit()}      >{"Save"}</Button>
                        </CardFooter>
                    </Card>
                </Col>
            </Row>

        </Fragment>
    )
}
export default AdminDropdown;