import React, { Fragment, useState } from "react";
import { useEffect } from "react";
import adminServices from "../../api/adminService";
import { Row, Col, Card, CardBody, CardHeader, Button } from "reactstrap";
import { Container } from "react-bootstrap";
import DataTable from "react-data-table-component";
import SweetAlert from 'sweetalert2'
import { useNavigate } from "react-router";
import Breadcrumb from "../../layout/breadcrumb";
import { classes } from '../../data/layouts'


const UserList = () => {

    const [data, setData] = useState("")
    const [selectedRows, setSelectedRows] = useState([]);
    const [toggleCleared, setToggleCleared] = useState(false);
    const history = useNavigate();
    const defaultLayoutObj = classes.find(item => Object.values(item).pop(1) === 'compact-wrapper');
    const layout = localStorage.getItem('layout') || Object.keys(defaultLayoutObj).pop();


    useEffect(() => {
        async function getUsers() {

            const res = await adminServices.getUsers("User");
            if (res.status) {
                setData(res.data)
            }
            else {
                setData("")
            }

        }
        getUsers()

    }, [0])


    const tableColumns = [
        {
            name: 'Name',
            selector: row => row.name,
            sortable: true,

        },
        {
            name: 'Email',
            selector: row => row.email,
            sortable: true,
            center: true,
        },
        {
            name: 'Mobile Number',
            selector: row => row.mobile,
            sortable: true,
            center: true,
        },
        {
            name: 'Type',
            selector: row => row.type,
            sortable: true,
            center: true,
        },
        {
            name: 'View',
            selector: row => {
                return <Button color="primary"
                    onClick={() => onViewNavigate(row)}>View</Button>


            },
            sortable: true,
            center: true,
        },
        {
            name: 'Action',
            selector: row => {
                return <div>
                    <span><i className="fa fa-pencil" style={{ width: 35, fontSize: 16, padding: 11, color: 'rgb(40, 167, 69)' }} onClick={() => onUpdateUser(row)}></i></span>
                    <span><i className="fa fa-trash" style={{ width: 35, fontSize: 16, padding: 11, color: '#e4566e' }} onClick={() => onRemoveUser(row.mobile)}></i></span>
                </div>
            },
            sortable: true,
            center: true,
        },
    ]



    const onViewNavigate = (row) => {
        history(`${process.env.PUBLIC_URL}/app/listingdata/${row._id}`, { state: row });
    }

    const onRemoveUser = (id) => {
        SweetAlert.fire({
            title: 'Are you sure?',
            text: "Once deleted, you will not be able to recover this user!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Ok',
            cancelButtonText: 'cancel',
            reverseButtons: true
        }).then((result) => {

            if (result.value) {
                const res = adminServices.deleteUser(id);

                SweetAlert.fire(
                    'Deleted!',
                    'Your User has been deleted.',
                    'success'
                )
            }
            else {
                SweetAlert.fire(
                    'Your file is safe!'
                )
            }
        })

    }
    const onUpdateUser = (row) => {
        history(`${process.env.PUBLIC_URL}/app/user/${row._id}`, { state: row });
    }


    return (
        <Fragment>
            <Breadcrumb parent="Table" title="Users" />
            <Container fluid={true}>
                <Row>
                    <Col sm="12">
                        <Card>

                            <CardBody>
                                <DataTable
                                    data={data}
                                    columns={tableColumns}
                                    center={true}
                                // selectableRows
                                // persistTableHead
                                // contextActions={contextActions}
                                // onSelectedRowsChange={handleRowSelected}
                                // clearSelectedRows={toggleCleared}
                                />
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </Fragment>
    )

}
export default UserList;