import React, { useState } from "react";
import { useEffect } from "react";
import adminServices from "../../api/adminService";
import { Row, Col, Card, CardBody, CardHeader, Button } from "reactstrap";
import { Container } from "react-bootstrap";
import DataTable from "react-data-table-component";
import SweetAlert from 'sweetalert2'
import { useNavigate } from "react-router";
import Breadcrumb from "../../layout/breadcrumb";
import { classes } from '../../data/layouts'
import { Fragment } from "react";
import { useFetcher } from "react-router-dom";

import Select from 'react-select'

const options = [
    { value: 'all', label: 'All' },
    { value: 'Pending', label: 'Pending' },
    { value: 'Approved', label: 'Approved' },
    { value: 'Draft', label: 'Draft' },
    { value: 'Rejected', label: 'Rejected' }
];


const ListingData = () => {
    const [data, setData] = useState("")

    const history = useNavigate();
    const defaultLayoutObj = classes.find(item => Object.values(item).pop(1) === 'compact-wrapper');
    const layout = localStorage.getItem('layout') || Object.keys(defaultLayoutObj).pop();

    const [status, setStatus] = useState("all");


    useEffect(() => {
        async function getList() {
            let qryString = "";
            if (status !== 'all') {
                qryString = new URLSearchParams({ status: status }).toString()
            }

            const res = await adminServices.getList(qryString);
            if (res.status) {
                setData(res.data)
            } else {
                setData([]);
            }
        }
        getList();

    }, [status])


    const tableColumns = [
        {
            name: 'Category',
            selector: row => row.equipment_id?.title,
            sortable: true,
        },
        {
            name: 'Name',
            selector: row => row.equipment_name,
            sortable: true,
        },
        {
            name: 'Brand',
            selector: row => row.brand,
            sortable: true,
            center: true,
        },
        {
            name: 'Model',
            selector: row => row.model,
            sortable: true,
            center: true,
        },
        {
            name: 'Year of MFG',
            selector: row => row.mrg_year,
            sortable: true,
            center: true,
        },
        {
            name: 'Status',
            selector: row => row.status,
            sortable: true,
            center: true,
        },
        {
            name: 'View',
            selector: row => {
                return <Button color="primary"
                    onClick={() => onUpdateStatus(row)}
                >View</Button>
            },
            sortable: true,
        }
    ]


    const onUpdateStatus = (row) => {
        history(`${process.env.PUBLIC_URL}/app/listing-info/${row._id} `, { state: row });
    }

    return (
        <Fragment>
            <Breadcrumb parent="Table" title="Listing"
                renderOptions={() => <Select value={status} onChange={event => {
                    setStatus(event.value);
                }} styles={{ width: '20%', margin: '2%' }} options={options} />} />

            <Container fluid={true}>
                <Row>
                    <Col sm="12">
                        <Card>
                            <CardBody>
                                <DataTable
                                    data={data}
                                    columns={tableColumns}
                                    center={true}
                                />
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </Fragment>
    )
}
export default ListingData;