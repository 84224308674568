import React, { Fragment, useState } from "react";
import { useEffect } from "react";
import adminServices from "../../api/adminService";
import { Row, Col, Card, CardBody, CardHeader, Button } from "reactstrap";
import { Container } from "react-bootstrap";
import DataTable from "react-data-table-component";
import SweetAlert from 'sweetalert2'
import { useLocation, useNavigate } from "react-router";
import Breadcrumb from "../../layout/breadcrumb";

import { classes } from '../../data/layouts'


const GetLisiting = () => {

    const location = useLocation();
    const userId = location.state;
    const [address, setAddress] = useState([]);

    const [data, setData] = useState("")
    const [selectedRows, setSelectedRows] = useState([]);
    const [toggleCleared, setToggleCleared] = useState(false);
    const history = useNavigate();
    const defaultLayoutObj = classes.find(item => Object.values(item).pop(1) === 'compact-wrapper');
    const layout = localStorage.getItem('layout') || Object.keys(defaultLayoutObj).pop();


    useEffect(() => {
        async function getUsers() {

            const res = await adminServices.getListing(userId._id);
            if (res.status) {
                setData(res.data)
            }
            else {
                setData("")
            }


            const addres = await adminServices.getAddress(userId._id);
            if (addres.status) {
                setAddress(addres.data);
            }

        }
        getUsers()

    }, [0])


    const onUpdateListing = (row) => {
        history(`${process.env.PUBLIC_URL}/app/listing/${layout}`, { state: { update: row } });

    }


    const tableColumns = [
        {
            name: 'Type',
            selector: row => row.equipment_id.title,
            sortable: true,

        },
        {
            name: 'Name',
            selector: row => row.equipment_name,
            sortable: true,

        },
        {
            name: 'User',
            selector: row => row.user_id.name +"("+ row.user_id.mobile + ")",
            sortable: true,

        },
        {
            name: 'Brand',
            selector: row => row.brand,
            sortable: true,
            center: true,
        },
        {
            name: 'Model',
            selector: row => row.model,
            sortable: true,
            center: true,
        },
        {
            name: 'Manufacturing Year',
            selector: row => row.mrg_year,
            sortable: true,
            center: true,
        },
        {
            name: 'Action',
            selector: row => {
                return <div>
                    <span><i
                        className="fa fa-pencil"
                        style={{ width: 35, fontSize: 16, padding: 11, color: 'rgb(40, 167, 69)' }}
                        onClick={() => onUpdateListing(row)}
                    ></i></span>

                </div>
            },

            center: true,
        },



    ]


    // const onRemoveUser = (id) => {
    //     SweetAlert.fire({
    //         title: 'Are you sure?',
    //         text: "Once deleted, you will not be able to recover this user!",
    //         icon: 'warning',
    //         showCancelButton: true,
    //         confirmButtonText: 'Ok',
    //         cancelButtonText: 'cancel',
    //         reverseButtons: true
    //     }).then((result) => {
    //         if (result.value) {
    //             adminServices.deleteUser(id)
    //             SweetAlert.fire(
    //                 'Deleted!',
    //                 'Your User has been deleted.',
    //                 'success'
    //             )
    //         }
    //         else {
    //             SweetAlert.fire(
    //                 'Your file is safe!'
    //             )
    //         }
    //     })

    // }
    // const onUpdateUser = (row) => {
    //     history(`${process.env.PUBLIC_URL}/app/user/${row._id}`, { state: row });
    // }

    const addListing = () => {
        history(`${process.env.PUBLIC_URL}/app/listing/${layout}`, { state: { user: userId._id } });
    }


    return (
        <Fragment>
            <Breadcrumb parent="Table" title="Listings" />
            <Container fluid={true}>

                <Row>
                    <Col sm="12">
                        <Card>
                            <CardHeader style={{ display: "flex", flexDirection: "row", justifyContent: "flex-end" }}>

                                <Button onClick={() => addListing()}>{"Add Listing"}</Button>
                                <Button style={{ marginLeft: 10 }} onClick={() => history(`${process.env.PUBLIC_URL}/app/address/${layout}`, { state: userId._id })}>{"Add Address"}</Button>


                            </CardHeader>
                            <CardBody>
                                <DataTable
                                    data={data}
                                    columns={tableColumns}
                                    center={true}

                                />
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </Fragment>
    )

}
export default GetLisiting;