import React, { Fragment, useState } from "react";
import Breadcrumb from "../../layout/breadcrumb";
import { Row, Col, Card, CardBody, CardHeader, Button } from "reactstrap";
import { Container } from "react-bootstrap";
import DataTable from "react-data-table-component";
import SweetAlert from 'sweetalert2'
import { useLocation, useNavigate } from "react-router";
import { API_BASE_URL } from "../../api/url";
import { classes } from "../../data/layouts";
import adminServices from "../../api/adminService";


const Equipments = () => {
    const location = useLocation();
    const categoryData = location.state;
    const data = categoryData?.equipments;



    const history = useNavigate();
    const defaultLayoutObj = classes.find(item => Object.values(item).pop(1) === 'compact-wrapper');
    const layout = localStorage.getItem('layout') || Object.keys(defaultLayoutObj).pop();






    const onUpdateEquipments = (row) => {

        history(`${process.env.PUBLIC_URL}/app/equip/${row._id}`, { state: { equipment: row, isEdit: true, category: categoryData } });
    }

    const tableColumns = [
        {
            name: 'Title',
            selector: row => row.title,
            sortable: true,

        },
        {
            name: 'Helpers',
            selector: row => row.required_helpers,
            sortable: true,
            center: true,
        },
        {
            name: 'Icon',
            selector: row => {
                if (row.icon) {
                    return <img
                        width={50}
                        height={50}
                        src={API_BASE_URL + row.icon}
                    />
                } else {
                    return <img
                        width={50}
                        height={50}
                        src={require("../../assets/images/Icons/logo.png")}
                    />
                }

            },
            sortable: true,

        },
        {
            name: 'Description',
            selector: row => row.description,
            sortable: true,
            center: true,
        },
        {
            name: 'Action',
            selector: row => {
                return <div>
                    <span><i className="fa fa-pencil" style={{ width: 35, fontSize: 16, padding: 11, color: 'rgb(40, 167, 69)' }}
                        onClick={() => onUpdateEquipments(row)}
                    ></i></span>
                    <span><i className="fa fa-trash" style={{ width: 35, fontSize: 16, padding: 11, color: '#e4566e' }}
                        onClick={() => onRemoveEquipment(row)}
                    ></i></span>
                </div>
            },
            sortable: true,
            center: true,
        },
    ]



    const onRemoveEquipment = (row) => {


        SweetAlert.fire({
            title: 'Are you sure?',
            text: "Once deleted, you will not be able to recover this user!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Ok',
            cancelButtonText: 'cancel',
            reverseButtons: true
        }).then((result) => {
            if (result.value) {
                adminServices.updateEquipment(row._id, { ...row, status: false })
                SweetAlert.fire(
                    'Deleted!',
                    'Your Category has been deleted.',
                    'success'
                )
            }
            else {
                SweetAlert.fire(
                    'Your file is safe!'
                )
            }
        })
    }


    return (
        <Fragment>
            <Breadcrumb parent="Table" title="Equipments" />
            <Container fluid={true}>
                <Row>
                    <Col sm="12">
                        <Card>
                            <CardHeader style={{ display: "flex", flexDirection: "row", justifyContent: "flex-end" }}>

                                <Button color="primary" onClick={() => history(`${process.env.PUBLIC_URL}/app/equip/${layout}`, { state: { category: categoryData, isEdit: false } })}>{"Add"}</Button>

                            </CardHeader>
                            <CardBody>
                                <DataTable
                                    data={data}
                                    columns={tableColumns}
                                    center={true}

                                />
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </Fragment>
    )

}
export default Equipments;