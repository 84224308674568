import React from 'react';
import { Card, CardBody } from 'reactstrap';

const JobSiteDetails = (props) => {

    const jobsite = props.jobsite;


    return (
        <Card>
            <CardBody>

                <h3>Jobsite Details</h3>
                <div className='pan-info'>
                    <p className='pan-text pan-label'>Name</p> <h6 className='pan-text pan-value'>{jobsite.name ? jobsite.name : "-"}</h6>
                </div>

                <div className='pan-info'>
                    <p className='pan-text pan-label'>Organization Name</p> <h6 className='pan-text pan-value'>{jobsite.org_name ? jobsite.org_name : "-"}</h6>
                </div>

                <div className='pan-info'>
                    <p className='pan-text pan-label'>PO Number</p> <h6 className='pan-text pan-value'>{jobsite.po_number ? jobsite.po_number : "-"}</h6>
                </div>

                <div className='pan-info'>
                    <p className='pan-text pan-label'>PO Number</p> <h6 className='pan-text pan-value'>{jobsite.po_number ? jobsite.po_number : "-"}</h6>
                </div>

                <div className='pan-info'>
                    <p className='pan-text pan-label'>Address</p> <h6 className='pan-text pan-value'>{jobsite.address ? jobsite.address?.city : "-"}</h6>
                </div>

                <h6>Delivery Info</h6>

                <div className='pan-info'>
                    <p className='pan-text pan-label'>Email</p> <h6 className='pan-text pan-value'>{jobsite.delivery_info?.email}</h6>
                </div>

                <div className='pan-info'>
                    <p className='pan-text pan-label'>Name</p> <h6 className='pan-text pan-value'>{jobsite.delivery_info?.name}</h6>
                </div>
                <div className='pan-info'>
                    <p className='pan-text pan-label'>Phone</p> <h6 className='pan-text pan-value'>{jobsite.delivery_info?.phone}</h6>
                </div>
                <div className='pan-info'>
                    <p className='pan-text pan-label'>Instructions</p> <h6 className='pan-text pan-value'>{jobsite.delivery_info?.instructions}</h6>
                </div>




            </CardBody>
        </Card>
    )
}

export default JobSiteDetails;