import requests from "./httpservices";

const adminServices = {

    userCreation(body) {

        return requests.post(`admin/user`, body)
    },
    updateUser(id, body) {
        return requests.post(`admin/user/${id}`, body)
    },
    getUsers(type) {
        return requests.get(`admin/users/${type}`)
    },
    deleteUser(id) {
        return requests.delete(`admin/user/${id}`)
    },
    addCategory(body) {
        return requests.post(`admin/category`, body)
    },
    updateCategory(body, id) {
        return requests.post(`admin/category/${id}`, body)
    },
    getCategory() {
        return requests.get(`common/category`)
    },
    categoryDelete(id) {
        return requests.delete(`admin/category/${id}`)
    },
    getBookings(query) {
        return requests.get(`admin/bookings?${query}`)
    },
    addEquipment(id, body) {
        return requests.post(`admin/equipment/${id}`, body)
    },
    updateEquipment(id, body) {
        return requests.post(`admin/equipments/${id}`, body)
    },
    getListing(id) {
        return requests.get(`admin/listing/${id}`)

    },
    addListing(body, id) {
        return requests.post(`admin/listing/${id}`, body)
    },
    dropDown(body, type) {
        return requests.post(`admin/dropdown/${type}`, body)
    },
    updateDropDown(id, body) {
        return requests.put(`admin/dropdown/${id}`, body)
    },
    getDropdowns() {
        return requests.get(`admin/dropdown`)
    },
    deleteDropDown(id) {
        return requests.delete(`admin/dropdown/${id}`)
    },
    getAddress(id) {
        return requests.get(`admin/address/${id}`);
    },
    addAddress(id, body) {
        return requests.post(`admin/address/${id}`, body);
    },
    addDocument(body) {
        return requests.post(`admin/document-type`, body);
    },
    getList(qry) {
        return requests.get(`admin/list?${qry}`);
    },
    getDocuments() {
        return requests.get(`admin/documents`);
    },
    updateDocument(id, body) {
        return requests.put(`admin/update-doument/${id}`, body)
    },
    deleteDocument(id) {
        return requests.delete(`admin/document/${id}`)
    },
    getEnquiries(id) {
        return requests.get(`admin/enquiries`);
    },
    updateListStatus(id, body) {
        return requests.post(`admin/listing-status/${id}`, body);
    },
    getBookingEnquiries(id) {
        return requests.get(`admin/booking-enquiries/${id}`);
    },
    updateEnqStatus(id, body) {
        return requests.post(`admin/enq-status/${id}`, body);
    },
    getEnquriesForQuotation(id) {
        return requests.get(`admin/quote-enq/${id}`);
    },
    saveQuotation(id, body) {
        return requests.post(`admin/quotation/${id}`, body);
    },
    getQuotations() {
        return requests.get(`admin/quotations`);
    }
}

export default adminServices;