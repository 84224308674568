import React, { useState, useEffect } from 'react';
import man from '../assets/images/dashboard/profile.jpg';
import { Container, Row, Col, Form, Input, Label, Button, NavItem, NavLink, Nav, TabContent, TabPane, FormFeedback, FormGroup } from 'reactstrap';

import { useAuth0 } from '@auth0/auth0-react'
import { toast } from 'react-toastify';

import { Password, SignIn, EmailAddress, RememberPassword, ForgotPassword, CreateAccount, LoginWithJWT, PhoneNumber, Email } from '../constant';
import { useNavigate } from 'react-router';
import { classes } from '../data/layouts';
import authServices from '../api/authServices';
import { useDispatch } from 'react-redux';
import { loginStatus, loginSuccess } from '../redux/authentication/action';
import { AlertTriangle } from 'react-feather';


const Logins = (props) => {

  const { loginWithRedirect } = useAuth0()
  const [phoneNumber, setPhoneNumber] = useState("9502932085");
  const [togglePassword, setTogglePassword] = useState(false);
  const [formValues, setFormValues] = useState({
    email: "admin@techdeets.net",
    password: "123456"
  })

  const [formErrors, setFormErrors] = useState({
    email: "",
    password: ""
  })

  const [loading, setLoading] = useState(false)
  const [selected, setSelected] = useState("firebase");
  const dispatch = useDispatch();
  const history = useNavigate();
  const defaultLayoutObj = classes.find(item => Object.values(item).pop(1) === 'compact-wrapper');
  const layout = localStorage.getItem('layout') || Object.keys(defaultLayoutObj).pop();

  useEffect(() => {
    const token = localStorage.getItem('userObj');
    const user = localStorage.getItem('userDetails');
    if (user) {
      dispatch(loginSuccess(JSON.parse(user)));
    }
  }, []);
  const loginAuth = async (e) => {
    e.preventDefault();

    let error = {};
    if (!formValues.email) {
      error.email = "Please enter the email";
    }
    if (!formValues.password) {
      error.password = "Please enter the Password";
    }

    if (Object.keys(error).length > 0) {
      setFormErrors(error);

      return;
    }


    setLoading(true)


    const res = await authServices.webLogin({ email: formValues.email, password: formValues.password })

    setLoading(false)


    if (res.status) {
      localStorage.setItem('userObj', 'Bearer ' + res.data.token.token)
      let d = {...res.data, token : res.data.token.token}
      localStorage.setItem('userDetails', JSON.stringify(d))
      dispatch(loginSuccess(d));
      history(`${process.env.PUBLIC_URL}/dashboard/default/${layout}`);
      // // setTimeout(() => {
      // //   history(`${process.env.PUBLIC_URL}/dashboard/default/${layout}`);
      // // }, 200);


      toast(res.message)

    } else {
      toast.error(res.message)
    }


  }





  const loginWithJwt = (e) => {



  };


  const handleChange = (e) => {
    setFormValues({ ...formValues, [e.target.name]: e.target.value });
    setFormErrors({ ...formErrors, [e.target.name]: "" });
  }


  return (
    <Container fluid={true} className="p-0">
      <Row>
        <Col xs="12">
          <div className="login-card">
            <div>

              <div className="login-main login-tab">

                <TabContent activeTab={selected} className="content-login">
                  <TabPane className="fade show" tabId={selected === "firebase" ? "firebase" : "jwt"}>
                    <Form className="theme-form mt-2">

                      <h4>{"Login to your site"}</h4>
                      <div className="mb-3 mt-1">

                        <Label className="col-form-label">{Email}</Label>

                        <Input
                          className="form-control"
                          type="email"
                          name="email"
                          required=""
                          onChange={(e) => handleChange(e)}
                          defaultValue={formValues.email}

                          valid={formErrors.email}
                          invalid={formErrors.email}
                        />

                        <FormFeedback>
                          {formErrors.email}
                        </FormFeedback>

                      </div>
                      <div className="mb-3 position-relative">
                        <Label className="col-form-label">{Password}</Label>
                        <Input className="form-control" type={togglePassword ? "text" : "password"}
                          valid={formErrors.password}
                          invalid={formErrors.password}
                          onChange={(e) => handleChange(e)}
                          name="password"
                          defaultValue={formValues.password}
                          required="" />

                        <FormFeedback>
                          {formErrors.password}
                        </FormFeedback>
                        <div className="show-hide" onClick={() => setTogglePassword(!togglePassword)}><span className={togglePassword ? "" : "show"}></span></div>
                      </div>


                      <div className="login-btn mb-0 mt-3">

                        {selected === "firebase" ?
                          <Button color="primary" disabled={loading ? loading : loading} onClick={(e) => loginAuth(e)}>{loading ? "LOADING..." : SignIn}</Button>
                          :
                          <Button color="primary" onClick={() => loginWithJwt(formValues)}>{LoginWithJWT}</Button>
                        }

                      </div>



                    </Form>
                  </TabPane>

                </TabContent>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </Container >
  );
}

// export default withRouter(Logins);
export default Logins;