import React, { useState } from "react";
import { Fragment } from "react";
import Breadcrumb from "../../layout/breadcrumb";
import { Container } from "react-bootstrap";
import { Card, CardBody, Col, Row, Button, CardHeader } from "reactstrap";
import { useLocation } from "react-router";
import adminServices from "../../api/adminService";
import { toast } from "react-toastify";



const UpdateListingStatus = () => {

    const location = useLocation();
    const data = location.state;

    const [loading, setLoading] = useState(false);

    const onUpdateStatus = (status) => {
        setLoading(true);
        const res = adminServices.updateListStatus(data._id, {
            status: status
        })

        setLoading(false);
        if (res.status) {
            toast(res.message)
        } else {
            toast.error(res.message)
        }
    }


    return (
        <Fragment>
            <Breadcrumb parent="Table" title="Listing Status" />
            <Container fluid={true}>
                <Row>
                    <Col sm="12">
                        <Card>
                            {/* <CardHeader>
                                <Button style={{ margin: 10 }}>Approved</Button>
                                <Button style={{ margin: 10 }}>Rejected</Button>
                            </CardHeader> */}
                            <CardBody >
                                {
                                    data.status == 'Pending' && <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-end", marginLeft: "15%", minHeight: 30 }}>
                                        <Button style={{ margin: 10 }} onClick={() => onUpdateStatus("Approved")}>Approve</Button>
                                        <Button style={{ margin: 10 }} onClick={() => onUpdateStatus("Rejected")}>Reject</Button>
                                    </div>

                                }
                                <div style={{ display: "flex", flexDirection: "row", width: "100%" }} >
                                    <div style={{ display: "flex", flexDirection: "column", width: "50%", justifyContent: "space-between" }}>
                                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                                            <p style={{ fontSize: 15, color: "#757575" }}>
                                                Brand
                                            </p>
                                            <h6 style={{ fontSize: 15 }}>{data.brand}</h6>
                                        </div>
                                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                                            <p style={{ fontSize: 15, color: "#757575" }}>
                                                Model
                                            </p>
                                            <h6 style={{ fontSize: 15 }}>{data.model}</h6>
                                        </div>
                                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                                            <p style={{ fontSize: 15, color: "#757575" }}>
                                                Manufacturing Year
                                            </p>
                                            <h6 style={{ fontSize: 15 }}>{data.mrg_year}</h6>
                                        </div>
                                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                                            <p style={{ fontSize: 15, color: "#757575" }}>
                                                Status
                                            </p>
                                            <h6 style={{ fontSize: 15 }}>{data.status}</h6>
                                        </div>
                                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                                            <p style={{ fontSize: 15, color: "#757575" }}>
                                                Stage
                                            </p>
                                            <h6 style={{ fontSize: 15 }}>{data.stage}</h6>
                                        </div>
                                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                                            <p style={{ fontSize: 15, color: "#757575" }}>
                                                Usage Hours
                                            </p>
                                            <h6 style={{ fontSize: 15 }}>{data.usage_hours}</h6>
                                        </div>
                                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                                            <p style={{ fontSize: 15, color: "#757575" }}>
                                                Usage Run
                                            </p>
                                            <h6 style={{ fontSize: 15 }}>{data.run}</h6>
                                        </div>

                                    </div>


                                </div>



                            </CardBody>



                        </Card>
                    </Col>
                </Row>
            </Container>
        </Fragment >
    )
}
export default UpdateListingStatus;