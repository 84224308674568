import React, { Fragment, useState } from "react";
import { useEffect } from "react";
import adminServices from "../../api/adminService";
import { Row, Col, Card, CardBody, CardHeader, Button } from "reactstrap";
import { Container } from "react-bootstrap";
import DataTable from "react-data-table-component";
import SweetAlert from 'sweetalert2'
import { useNavigate } from "react-router";
import Breadcrumb from "../../layout/breadcrumb";
import { classes } from '../../data/layouts'


const DocumentList = () => {

    const [data, setData] = useState("")

    const history = useNavigate();
    const defaultLayoutObj = classes.find(item => Object.values(item).pop(1) === 'compact-wrapper');
    const layout = localStorage.getItem('layout') || Object.keys(defaultLayoutObj).pop();


    useEffect(() => {
        async function getDocuments() {

            const res = await adminServices.getDocuments();
            if (res.status) {
                setData(res.data)
            }
            else {
                setData("")
            }

        }
        getDocuments()

    }, [0])



    const tableColumns = [
        {
            name: 'Name',
            selector: row => row.name,
            sortable: true,

        },
        {
            name: 'Key',
            selector: row => row.key,
            sortable: true,
            center: true,
        },
        {
            name: 'Type',
            selector: row => row.type,
            sortable: true,
            center: true,
        },
        {
            name: 'Backpage',
            selector: row => row.backpage,
            sortable: true,
            center: true,
        },
        {
            name: 'Action',
            selector: row => {
                return <div>
                    <span><i className="fa fa-pencil" style={{ width: 35, fontSize: 16, padding: 11, color: 'rgb(40, 167, 69)' }} onClick={() => onUpdateDocument(row)}></i></span>
                    <span><i className="fa fa-trash" style={{ width: 35, fontSize: 16, padding: 11, color: '#e4566e' }} onClick={() => onRemoveDocument(row._id)}></i></span>
                </div>
            },
            sortable: true,
            center: true,
        },
    ]


    const onRemoveDocument = (id) => {
        SweetAlert.fire({
            title: 'Are you sure?',
            text: "Once deleted, you will not be able to recover this user!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Ok',
            cancelButtonText: 'cancel',
            reverseButtons: true
        }).then((result) => {
            if (result.value) {
                adminServices.deleteDocument(id)
                SweetAlert.fire(
                    'Deleted!',
                    'Your User has been deleted.',
                    'success'
                )
            }
            else {
                SweetAlert.fire(
                    'Your file is safe!'
                )
            }
        })

    }
    const onUpdateDocument = (row) => {
        history(`${process.env.PUBLIC_URL}/app/document/${row._id}`, { state: row });
    }


    return (
        <Fragment>
            <Breadcrumb parent="Table" title="Documents List" />
            <Container fluid={true}>
                <Row>
                    <Col sm="12">
                        <Card>
                            <CardHeader style={{ display: "flex", flexDirection: "row", justifyContent: "flex-end" }}>

                                <Button color="primary" onClick={() => history(`${process.env.PUBLIC_URL}/app/document/${layout}`)}>{"Add Document"}</Button>
                            </CardHeader>
                            <CardBody>
                                <DataTable
                                    data={data}
                                    columns={tableColumns}
                                    center={true}
                                // selectableRows
                                // persistTableHead
                                // contextActions={contextActions}
                                // onSelectedRowsChange={handleRowSelected}
                                // clearSelectedRows={toggleCleared}
                                />
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </Fragment>
    )

}
export default DocumentList;