import React, { Fragment, useState } from "react";
import { useEffect } from "react";
import adminServices from "../../api/adminService";
import { Row, Col, Card, CardBody, CardHeader, Button } from "reactstrap";
import { Container } from "react-bootstrap";
import DataTable from "react-data-table-component";
import SweetAlert from 'sweetalert2'
import { useLocation, useNavigate } from "react-router";
import Breadcrumb from "../../layout/breadcrumb";

import { classes } from '../../data/layouts'


const DropDownList = () => {


    const [data, setData] = useState("");

    const history = useNavigate();
    const defaultLayoutObj = classes.find(item => Object.values(item).pop(1) === 'compact-wrapper');
    const layout = localStorage.getItem('layout') || Object.keys(defaultLayoutObj).pop();
    const [dropValues, setDropValues] = useState();


    useEffect(() => {

        let dropData = [];
        for (var k in data) {

            dropData.push(data[k])
        }
        setDropValues(dropData)

    }, [data])



    useEffect(() => {
        async function getDropDowns() {

            const res = await adminServices.getDropdowns();

            if (res.status) {
                setData(res.data);

            }
            else {
                setData("")
            }


        }

        getDropDowns()

    }, [0])






    const tableColumns = [
        {
            name: 'Title',
            selector: row => row.title,
            sortable: true,

        },
        {
            name: 'Type',
            selector: row => row.type,

            center: true,
        },
        {
            name: 'Value',
            selector: row => row.value,

            center: true,
        },
        {
            name: 'Action',
            selector: row => {
                return <div>
                    <span><i className="fa fa-pencil" style={{ width: 35, fontSize: 16, padding: 11, color: 'rgb(40, 167, 69)' }} onClick={() => onUpdateDropDown(row)}></i></span>
                    <span><i className="fa fa-trash" style={{ width: 35, fontSize: 16, padding: 11, color: '#e4566e' }} onClick={() => onRemoveDropDown(row._id)}></i></span>
                </div>
            },

            center: true,
        },



    ]


    const onRemoveDropDown = (id) => {
        SweetAlert.fire({
            title: 'Are you sure?',
            text: "Once deleted, you will not be able to recover this user!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Ok',
            cancelButtonText: 'cancel',
            reverseButtons: true
        }).then((result) => {
            if (result.value) {
                adminServices.deleteDropDown(id)
                SweetAlert.fire(
                    'Deleted!',
                    'Your Ttile has been deleted.',
                    'success'
                )
            }
            else {
                SweetAlert.fire(
                    'Your file is safe!'
                )
            }
        })

    }
    const onUpdateDropDown = (row) => {
        history(`${process.env.PUBLIC_URL}/app/dropdown/${row._id}`, { state: row });
    }

    const addDropdown = () => {
        history(`${process.env.PUBLIC_URL}/app/dropdown/${layout}`);
    }


    return (
        <Fragment>
            <Breadcrumb parent="Table" title="DropDowns" />
            <Container fluid={true}>

                <div style={{ marginBottom: '24px', display: 'flex', justifyContent: "flex-end" }}>

                    <Button onClick={() => addDropdown()} color="primary">{"Add"}</Button>
                </div>

                <Row>
                    <Col sm="12">

                        {
                            dropValues?.map((item) => {
                                return <Card>
                                    <CardHeader style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                                        <h5>{item._id}</h5>


                                    </CardHeader>
                                    <CardBody>

                                        <DataTable
                                            data={item.data}
                                            columns={tableColumns}
                                            center={true}

                                        />




                                    </CardBody>
                                </Card>

                            })
                        }

                    </Col>
                </Row>
            </Container>
        </Fragment>
    )

}
export default DropDownList;